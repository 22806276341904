import React, {useState} from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import {renderToString} from "react-dom/server";
import {gtag_report} from '../helpers'

const Careers = () => {
  const [sent, setSent] = useState(false);
  const [formState, setFormState] = useState({
    name: "",
    email: "",
    message: "",
  });

  const getEmail = () => {
    return <div>
      <p>From careers</p>
      <p>Name: {formState.name}</p>
      <p>Email: {formState.email}</p>
      <p>Message: {formState.message}</p>
    </div>
  };
  const onChange = (e) => {
    setFormState({...formState, [e.target.name]: e.target.value});
  };

  const submitForm = async (e) => {
    e.preventDefault();
    try {
      await fetch("/.netlify/functions/sendmail", {
        method: "POST",
        body: renderToString(getEmail()),
      });
      gtag_report()
      setSent(true);
    } catch (e) {
    }
  };
    const formIsValid = () => {
        return formState.name.length > 0 &&  formState.email.length > 0 && formState.message.length > 0
    };
  return (
    <Layout>
      <SEO title="Nunuma - Modern Web Application Developer Careers" description="Growing a customer focused, diverse team, with attention to detail and a passion to solve challenges big and small as we help businesses succeed in their digital transformation."/>
      <div className={`mx-auto max-w-md flex flex-col items-center mb-20`}>
        <p className={`text-sm md:text-base text-center font-bold text-purple-800`}>Careers</p>
        <div className={`w-16 border-b-4 border-orange-500 my-4`}/>
        <p className={`text-center text-purple-800 mb-2`}>We are not actively hiring at the moment. We would still love to hear from you anyway, so don’t be shy to start a conversation.</p>
      </div>
      <div className={`relative h-full mb-32`}>
        <div style={{height: '400px', transform: `skewY(5deg)`}} className={`w-full absolute bg-purple-100`}/>
        <div style={{height: '400px', transform: `skewY(-5deg)`}} className={`w-full relative bg-purple-800`}/>
        {!sent &&
        <div style={{marginTop: '-440px', boxShadow: 'rgba(60, 66, 87, 0.12) 0px 7px 14px 0px, rgba(0, 0, 0, 0.12) 0px 3px 6px 0px'}} className={`absolute -mt-64 max-w-md rounded-lg bg-white shadow left-0 right-0 mx-auto p-6 text-purple-800`}>
          <h1 className={`font-bold mb-4 ml-2`}>Let us know more about you</h1>
          <div className={`mb-4`}>
            <label htmlFor={`name`} className={`font-bold text-sm mb-1 ml-2`}>Name</label>
            <input type={`text`} onChange={onChange} name={`name`} className={`px-4 py-2 border border-purple-100 w-full rounded-md`}/>
          </div>
          <div className={`mb-4`}>
            <label htmlFor={`email`} className={`font-bold text-sm mb-1 ml-2`}>Email</label>
            <input id={`email`} type={`email`} onChange={onChange} name={`email`} className={`px-4 py-2 border border-purple-100 w-full rounded-md`}/>
          </div>
          <div className={`mb-4`}>
            <label htmlFor={`message`} className={`font-bold text-sm mb-1 ml-2`}>Your message
            </label>
            <textarea style={{minHeight: '180px'}} id={`message`} onChange={onChange} name={`message`} className={`px-4 py-2 border border-purple-100 w-full rounded-md`}/>
          </div>
            <button disabled={!formIsValid()} onClick={submitForm} className={`${!formIsValid() ? 'opacity-50 cursor-not-allowed ': ''} bg-purple-800 px-8 py-2 rounded text-white font-medium hover:shadow`}>Send</button>
        </div>}
        {sent &&
        <div style={{marginTop: '-240px', boxShadow: 'rgba(60, 66, 87, 0.12) 0px 7px 14px 0px, rgba(0, 0, 0, 0.12) 0px 3px 6px 0px'}} className={`absolute -mt-64 max-w-md rounded-lg bg-white shadow left-0 right-0 mx-auto p-6 text-purple-800`}>
          <div className={'flex items-center justify-center'}>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fillRule="evenodd" clipRule="evenodd" d="M12 23C5.92487 23 1 18.0751 1 12C1 5.92487 5.92487 1 12 1C18.0751 1 23 5.92487 23 12C23 18.0751 18.0751 23 12 23ZM12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21ZM15.2929 8.29289L10 13.5858L7.70711 11.2929L6.29289 12.7071L10 16.4142L16.7071 9.70711L15.2929 8.29289Z" fill="#59CF8A"/>
            </svg>
            <h1 className={` text-lg ml-2`}>All set! Looking forward to talking to you.</h1>
          </div>
        </div>}
      </div>
    </Layout>
  )
};

export default Careers
